import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql, Link }  from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from '../components/Div'
import H4 from '../components/H4'
import H5 from '../components/H5'
import Paragraph from '../components/Paragraph'

import { Container } from '../components/styles/Layout.styles'

import { 
  styledCloudServer,
  header,
  scrollTo
} from '../components/styles/Contrato.styles'

export const PageTemplate = ({
  data,
  ...rest
}) => {
  return (
    <Fragment>
      <section css={styledCloudServer}>
        <Container>
          <Row
            flexDirection={['column', 'row']}
            css={header}
          >
            <Column
              width={[1]}
            >
              <H4>Política de Utilização de Recursos (PUR)</H4>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row']}
            pt={40}
            pb={40}
          >
            <Column
              mb={[30, 30, 0, 0]}
            >
              <Div
                css={scrollTo}
              >
                <H5>Política de Utilização de Recursos (PUR)</H5>
                <Link
                  to='/termos-de-servico'
                >
                  Voltar para Termos do Serviço
                </Link>
              </Div>
            </Column>
            <Column>
              <Div
                maxWidth={['100%', '100%', 422]}
              >
                <Div>
                  <Paragraph><span>Esta política se refere aos serviços compartilhados de hospedagem, revenda de hospedagem e VPS. </span></Paragraph>
                  <Paragraph><span>1.</span> Cobertura</Paragraph>
                  <Paragraph>Esta Política de Utilização de Recursos (PUR) aplica-se se você tem conosco qualquer plano de hosting e se encontra adimplente de suas cobranças na HostDime.com.br. Termos não definidos nesta PUR seguem as regras anteriormente definidas em seu contrato com a HostDime.</Paragraph>
                  <Paragraph><span>2.</span> Uso de Recursos</Paragraph>
                  <Paragraph>A quaisquer websites em nossos servidores compartilhados que utilizam uma grande quantidade de recursos do servidor (CPU, utilização de memória e recursos de rede) será dada a opção de realizar o upgrade para um servidor dedicado ou VPS. O abuso e o mau uso de recursos do sistema compartilhado é um problema sério. Contas em um servidor compartilhado devem compartilhar recursos com outros usuários, por isso temos de limitar a quantidade de CPU e de memória que podem ser usados por qualquer conta.</Paragraph>
                  <Paragraph>Qualquer dúvida sobre a nossa Política de Utilização de Recursos poderá ser respondida através do contato com nossa equipe, por meio de sua Área do Cliente.</Paragraph>
                  <Paragraph><span>3. </span>Limites Comuns de Utilização</Paragraph>
                  <ul>
                    <li>&#9679; Utilização de memória Máximo de 10% por domínio/arquivo/aplicação. Ao violar esta regra, a conta poderá ser suspensa a qualquer momento, sem aviso prévio;</li>
                    <li>&#9679; Utilização do CPU Máximo de 10% por domínio/arquivo/aplicação. Ao violar esta regra, o site em questão apresentará lentidão devido ao controle automático do próprio sistema. Adicionalmente a conta também poderá ser suspensa a qualquer momento, sem aviso prévio;</li>
                    <li>&#9679; Conexões (persistentes) ao Apache Máximo de 40 (distintos IPs). Ao violar esta regra, a conta poderá ser suspensa a qualquer momento, sem aviso prévio;</li>
                    <li>&#9679; Conexões ao Apache para conteúdo dinâmico (PHP, etc): Máximo de 25 conexões simultâneas (distintos IPs). Ao violar esta regra, o site em questão apresentará o erro 503 até que o número de conexões simultâneas decresça;</li>
                    <li>&#9679; Número de usuários conectados no MySQL: Máximo de 15 (por conta);</li>
                    <li>&#9679; Limite de frequência de verificação de e-mail (POP3 ou IMAP): 12 por hora por e-mail ou 120 por hora por domínio;</li>
                    <li>&#9679; Máximo de conexões no servidor POP3 (por IP): 15~20;</li>
                    <li>&#9679; Máximo de conexões no servidor Apache (por IP): 50;</li>
                    <li>&#9679; Máximo de 350 emails por hora, por domínio;</li>
                    <li>&#9679; Leitura e escrita no disco (I/O): 512 Kbps;</li>
                    <li>&#9679; Conexões Recorrentes: 20;</li>
                    <li>&#9679; Processos de Entrada: 20.</li>
                  </ul>
                  <Paragraph>Há exceções especiais para estas regras que serão determinadas na hora que descobrirmos o abuso. Há diferentes combinações de utilização que podem criar problemas e outros casos especiais. A qualquer tempo que um servidor estiver instável e encontrarmos a conta que estiver causando isso, nós nos reservamos o direito de suspender a conta para manter o servidor online e operando normalmente. Se a utilização é crescente e constante, nós recomendamos ao cliente investir em um servidor dedicado, para que então seu website possa crescer em uma taxa normal sem restrições.</Paragraph>
                  <Paragraph><span>4.</span> Suspensões</Paragraph>
                  <Paragraph>No caso de seu site ser suspenso por abuso de recursos (abuso que não puder ser resolvido com a remoção/alteração do script, etc) e não quiser fazer o upgrade para um servidor dedicado ou VPS para acomodar o que seu site precisa, nós só iremos tirar a suspensão no período entre 24 a 48 horas. Isto é para garantir a estabilidade dos nossos servidores. Sites com histórico de suspensão acima de 3, não poderão ser mais hospedados em servidores compartilhados. Um backup do seu site será então providenciado por um período determinado. Qualquer dúvida sobre nossa política de utilização pode ser respondida através de sua Área do Cliente.</Paragraph>
                  <Paragraph><span>5.</span> Inodes</Paragraph>
                  <Paragraph>Contas de servidores compartilhados não podem ter número de inodes excessivos. Se for localizada alguma conta com número de inodes superior a 100.000 (cem mil) esta conta poderá ter os backups removidos e desativados permanentemente. A conta também não poderá mais escrever no disco. Caso o número de inodes seja igual ou superior a 250.000 (duzentos e cinquenta mil) a conta poderá ser suspensa a qualquer momento sem aviso prévio. Cada arquivo hospedado conta como 1 (um) inode, isto inclui arquivos na conta e e-mails.</Paragraph>
                  <Paragraph><span>6.</span> Cron</Paragraph>
                  <Paragraph>Nós permitimos que os clientes configurem o cron para executar funções de rotina automaticamente. Qualquer cliente que agendar crons, onde a execução dure mais da metade do tempo para concluir antes da próxima execução programada, ou que demore mais do que 10 minutos, será considerado como abuso do sistema cron. Quaisquer processos automatizados que consomem recursos excessivamente e comprometam a estabilidade do servidor poderão ser desativados a nosso critério por nossos técnicos.</Paragraph>
                  <Paragraph><span>7.</span> Serviços de VPS</Paragraph>
                  <Paragraph>A fim de garantir que uma única VPS não utilize recursos em excesso do servidor (CPU / RAM), nós utilizamos limites virtuais para implementar certos controles. Os limites ou barreiras, definidos são limite de processos 'numproc', utilização de memória RAM e limites de uso de CPU. O limite 'numproc' não está diretamente relacionado a quantidade de processos que são instanciados, e sim com o número de processos a nível de kernel que qualquer VPS instância. É desta forma que é feito o controle de processos a nível de kernel. Por favor, veja abaixo os limites especificados.</Paragraph>
                  <Paragraph>Limite de Processos 'numproc':</Paragraph>
                  <ul>
                    <li>VPS USA/POS/SPO I - 220:220</li>
                    <li>VPS USA/POS/SPO II - 260:260</li>
                    <li>VPS USA/POS/SPO III - 300:300</li>
                  </ul>
                  <Paragraph>Limitamos a quantidade de memória RAM que cada VPS irá utilizar. Qualquer conta de VPS só poderá utilizar o limite de memória que lhe foi garantido.</Paragraph>
                  <Paragraph>Utilização de Memória:</Paragraph>
                  <ul>
                    <li>&#9679; VPS USA/POS/SPO I - 2048MB</li>
                    <li>&#9679; VPS USA/POS/SPO II - 3072MB</li>
                    <li>&#9679; VPS USA/POS/SPO III - 4096MB</li>
                  </ul>
                  <Paragraph>Limite no uso de CPU</Paragraph>
                  <Paragraph>Nossas VPS são definidas com um limite de igual no uso CPU para cada conta, os limites de CPU são:</Paragraph>
                  <ul>
                    <li>&#9679; VPS USA I/II/III- 700MHz garantido, podendo chegar até 850MHz</li>
                  </ul>
                  <Paragraph>Qualquer conta de VPS que ultrapassar os limites acima, será considerada como uso "abusivo". Se este abuso de recursos ocorrer por mais de 12 (doze) horas, correrá o risco de ser suspensa. A HostDime Brasil reserva-se ao direito de suspender, sem aviso prévio, qualquer conta de VPS que ultrapassar esses limites. Neste caso a HostDime irá notificar o responsável pela VPS após a sua suspensão.</Paragraph>
                </Div>
              </Div>
            </Column>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object,
  values: PropTypes.object
}

const StaticPage = (props) => {
  return (
    <Layout>
      <Seo title='Política de Utilização de Recursos (PUR)' />
      <PageTemplate
        title='Política de Utilização de Recursos (PUR)'
        {...props}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "cloud-server/cloud-server.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default (StaticPage)
